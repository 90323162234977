// Translated
<template>
  <AuthForm class="login-page flex flex-col items-center">
    <div
      v-if="!isLoggedIn || !token"
      class="login-form w-full"
    >
      <AuthFeedback minimal />
      <LoginWithFacebook />
      <LoginWithGoogle />
      <TextInput
        id="email"
        v-model="email"
        :placeholder="$t('formEmail')"
        :v$="v$"
        @on-click-enter="login"
      >
        <template #label>
          <span data-i18="formEmail">
            {{ $t('formEmail') }}
          </span>
        </template>
        <template #validation="v">
          <span
            v-if="!v?.required"
            data-i18="formFieldRequiredError"
          >{{ $t('formFieldRequiredError') }}</span>
          <span
            v-else-if="!v?.email"
            data-i18="formFieldInvalidEmail"
          >{{ $t('formFieldInvalidEmail') }}</span>
        </template>
      </TextInput>
      <TextInput
        id="password"
        v-model="password"
        type="password"
        :placeholder="$t('formPassword')"
        :v$="v$"
        @on-click-enter="login"
      >
        <template #label>
          <span data-i18="formPassword">
            {{ $t('formPassword') }}
          </span>
        </template>
        <template #validation="v">
          <span
            v-if="!v?.required"
            data-i18="formFieldRequiredError"
          >{{ $t('formFieldRequiredError') }}</span>
        </template>
      </TextInput>
      <SubmitButton
        id="login-submit"
        :disabled="loading"
        :loading="loading"
        data-i18="memberLogIn"
        @click="login"
      >
        {{ $t('memberLogIn') }}
      </SubmitButton>
      <nuxt-link
        class="block mt-4"
        :to="authLinkTo(AuthPages.forgot)"
        data-i18="memberForgot"
      >
        {{ $t('memberForgot') }}
      </nuxt-link>
      <nuxt-link
        class="block mt-4"
        :to="authLinkTo(AuthPages.signup)"
        data-i18="memberSignup"
      >
        {{ $t('memberSignup') }}
      </nuxt-link>
      <nuxt-link
        class="block mt-4"
        :to="authLinkTo(AuthPages.whatsThis)"
        data-i18="memberWhatsThisTitle"
      >
        {{ $t('memberWhatsThisTitle') }}
      </nuxt-link>
    </div>
    <div
      v-else
      class="text-center"
    >
      <Loading />
      <p data-i18="memberLoggedInFeedback">
        {{ $t('memberLoggedInFeedback') }}
      </p>
    </div>
  </AuthForm>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import statuses from '@layers/web/constants/statuses'
import { AuthPages } from '@layers/web/utils/auth/auth'
import cognitoMixin from '@layers/web/mixins/cognito.mixin'

export default defineNuxtComponent({
  setup: () => ({ v$: useVuelidate() }),

  mixins: [cognitoMixin],

  emits: ['success'],

  validations: {
    email: { required, email },
    password: { required },
  },

  data () {
    return {
      email: '',
      password: '',
      loading: false,
      AuthPages,
    }
  },

  computed: {
    ...mapState(useUserStore, {
      token: 'getTokenForRequest',
      isLoggedIn: 'isLoggedIn',
    }),
  },

  methods: {
    ...mapActions(useUserStore, {
      clearUserForLogin: 'clearUserForLogout',
    }),

    login () {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.initiatingSocialLogin()

      this.loading = true
      this.cognitoHandleLogin(this.email, this.password)
        .then((e) => {
          this.loading = false
          if (e.status === statuses.ok) {
            this.afterLogin()
            this.$emit('success')
          }
        })
        .catch(() => {})
    },
  },
})
</script>
